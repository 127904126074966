/* nitti */
@font-face{
  font-family: 'Nitti Grotesk';
  font-weight: 300;
  font-style: normal;
  src: font-url('../public/nitti/NittiGrotesk-Light.woff2') format('woff2'), font-url('../public/nitti/NittiGrotesk-Light.woff') format('woff');
}
@font-face{
  font-family: 'Nitti Grotesk';
  font-weight: 400;
  font-style: normal;
  src: font-url('../public/nitti/NittiGrotesk-Medium.woff2') format('woff2'), font-url('../public/nitti/NittiGrotesk-Medium.woff') format('woff');
}
/* Leitura News */
@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Roman300.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Roman300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Roman400.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Roman400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Roman600.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Roman600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Roman700.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Roman700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Italic300.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Italic300.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Italic400.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Italic400.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Italic600.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Italic600.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face{
  font-family: 'Leitura News';
  src: font-url('../public/leitura/LeituraNews-Italic700.woff2') format('woff2'), font-url('../public/leitura/LeituraNews-Italic700.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

.MuiContainer-root{
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: Nitti Grotesk, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
